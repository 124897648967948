import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/community-portal/community-portal/src/modules/layouts/mdx_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Callout = makeShortcode("Callout");
const Indent = makeShortcode("Indent");
const List = makeShortcode("List");
const Column = makeShortcode("Column");
const Box = makeShortcode("Box");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Vault Risk`}</h1>
    <Callout icon="warning" mdxType="Callout">
      <p>{`Owning a Vault is inherently risky.`}</p>
    </Callout>
    <p>{`This page describes the types of risk associated with owning a Vault and provides common practices for mitigating those risks. There are four major categories of risks to consider when creating a Vault: Market Risks, User Risks, Systemic Risks, and Parameter Variability Risk.`}</p>
    <hr></hr>
    <Indent pad={2} mdxType="Indent">
      <h2>{`Market Risks`}</h2>
      <Indent pad={3} mdxType="Indent">
        <p>{`Using a Vault involves generating Dai and transferring ownership of your assets to a smart-contract that can sell your assets in the event of a market downturn. Any Vault with Generated Dai has a Liquidation Price, the price of the underlying asset at which one's Vault would be liquidated. Using a Vault for leverage introduces additional risk. The potential for reward is higher through leverage, but the potential for loss is magnified as well.`}</p>
        <p><em parentName="p">{`It is a common practice among users to maintain a high Collateralization Ratio to protect from Market Risks and thereby Liquidation.`}</em></p>
      </Indent>
      <h2>{`User Risks`}</h2>
      <Indent pad={3} mdxType="Indent">
        <p>{`This type of risk is associated with user error. MakerDAO can NOT reverse transactions or recover funds sent to incorrect addresses or contracts.`}</p>
      </Indent>
      <h2>{`Systemic Risks`}</h2>
      <Indent pad={3} mdxType="Indent">
        <p>{`Systemic risks associated with operation of the Maker Protocol include, but are not limited to:`}</p>
        <p>{`Malicious hacks attack against the smart-contract infrastructure.`}</p>
        <p>{`Black Swan events involving one or more collateral assets.`}</p>
        <p>{`Failure of centralized infrastructure. For example, failed internet connections, MetaMask bugs, etc.`}</p>
      </Indent>
      <h2>{`Parameter Variability Risk`}</h2>
      <Indent pad={3} mdxType="Indent">
        <p><strong parentName="p">{`It's important to note that Vault owners are subject to changes in the Risk Parameters that govern the system. This equates to financial risk for the Vault owner. Below is a partial list of parameters that are subject to change:`}</strong></p>
        <List mdxType="List">
          <p><a parentName="p" {...{
              "href": "/learn/vaults/stability-fees/"
            }}>{`Stability Fees`}</a></p>
          <p><a parentName="p" {...{
              "href": "/faqs/makerdao-mcd-faqs/glossary/"
            }}>{`Debt Ceiling`}</a></p>
          <p><a parentName="p" {...{
              "href": "/learn/vaults/liquidation/"
            }}>{`Liquidation Penalty`}</a></p>
        </List>
      </Indent>
    </Indent>
    <Column mdxType="Column">
      <Box mdxType="Box">
        <h2>{`Mitigating Risk`}</h2>
        <p><strong parentName="p">{`Common practices for mitigating the risks associated with owning a Vault include:`}</strong></p>
        <Box mdxType="Box">
          <p>{`Using price alerts and/or notifications to monitor Vaults`}</p>
          <p>{`Increasing the collateralization ratio of a Vault`}</p>
          <p>{`Maintaining enough liquidity outside of your Vault to pay back Dai generated by the Vault or add to its deposited collateral`}</p>
        </Box>
      </Box>
    </Column>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      